/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { Link } from "gatsby"

import Layout from "../../components/Layout"

import Phone from "../../assets/images/svg/phone-sms.svg"
import GoBack from "../../assets/images/svg/go-back.svg"

const Pix = ({ pageContext: translation, navigate }) => {
  return (
    <Layout translation={translation}>
      <section
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0 20px 50px;
        `}
      >
        <header
          css={css`
            position: relative;
            text-align: center;
          `}
        >
          <Link
            href="/"
            css={css`
              position: absolute;
              left: 0;
            `}
          >
            <GoBack />
          </Link>

          <h2 style={{ color: "#203760", marginBottom: "43px" }}>SMS</h2>
        </header>

        <p
          css={css`
            color: #203760;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
          `}
        >
          Mande mensagem para o número <strong>28453</strong>, enviando a
          palavra <strong>Doar</strong> seguida do valor que deseja fazer a
          doação.
          <br />
          <br />
          <strong>Ex: Doar 10</strong>
        </p>

        <Phone
          css={css`
            display: block;
            width: 100%;
            margin: 0 auto -225px;
          `}
        />
      </section>
    </Layout>
  )
}

export default Pix
